import {
  NOTIFICATION_STATUS,
  NotificationGetAllDtoType,
  NotificationGetPreviousDtoType,
  NotificationType,
  UpdateManyResultType,
} from '@finance-ops/types';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { updateManyNotificationStatuses } from '../notification';
import { baseQuery } from './baseQuery';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: baseQuery,
  tagTypes: ['Notification'],
  endpoints: builder => ({
    updateNotificationStatus: builder.mutation<UpdateManyResultType, { ids: string[]; status: NOTIFICATION_STATUS }>({
      query: ({ ids, status }) => ({
        url: `/notifications/updateMany/status`,
        method: 'PATCH',
        body: { ids, status },
      }),
      async onQueryStarted({ ids, status }, { dispatch, queryFulfilled }) {
        try {
          dispatch(updateManyNotificationStatuses(ids.map(id => ({ id, status }))));
        } catch {
          dispatch(updateManyNotificationStatuses(ids.map(id => ({ id, status: NOTIFICATION_STATUS.UNREAD }))));
        }
      },
    }),

    fetchNotifications: builder.query<NotificationType[], NotificationGetAllDtoType>({
      query: request => ({
        url: `/notifications`,
        method: 'GET',
        params: request,
      }),
    }),

    fetchPreviousNotifications: builder.query<NotificationType[], NotificationGetPreviousDtoType>({
      query: request => ({
        url: `/notifications/previous`,
        method: 'GET',
        params: request,
      }),
    }),
  }),
});

export const { useUpdateNotificationStatusMutation, useFetchNotificationsQuery, useFetchPreviousNotificationsQuery } =
  notificationsApi;
