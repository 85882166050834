import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Call, Device } from '@twilio/voice-sdk';
import { TransformedCall } from '../pages/chat-beta/components/call-container/CallHistory';

type CallSlice = {
  name: string;
  callToNumber: string;
  activeCall: boolean;
  device: Device | undefined;
  call: Call | undefined;
  voiceCallHistory: TransformedCall[];
  showSingleVoiceCallForDemo: boolean;
  isVoiceCallSelected: boolean;
};

//test hotfix

const initialState: CallSlice = {
  name: '',
  callToNumber: '',
  device: undefined,
  call: undefined,
  activeCall: false,
  voiceCallHistory: [],
  showSingleVoiceCallForDemo: false,
  isVoiceCallSelected: true,
};

const callSlice = createSlice({
  name: 'call',
  initialState,
  reducers: {
    setActiveCall: (state, action: PayloadAction<boolean>) => {
      state.activeCall = action.payload;
    },
    setDevice: (state, action: PayloadAction<Device | undefined>) => {
      state.device = action.payload;
    },
    setCall: (state, action: PayloadAction<Call | undefined>) => {
      state.call = action.payload;
    },
    setCallToNumber: (state, action: PayloadAction<string>) => {
      state.callToNumber = action.payload;
    },
    setCallName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setVoiceCallHistory: (state, action: PayloadAction<TransformedCall[]>) => {
      state.voiceCallHistory = action.payload;
    },
    setShowSingleVoiceCallForDemo: (state, action: PayloadAction<boolean>) => {
      state.showSingleVoiceCallForDemo = action.payload;
    },

    setIsVoiceCallSelected: (state, action: PayloadAction<boolean>) => {
      state.isVoiceCallSelected = action.payload;
    },
  },
});

export const {
  setActiveCall,
  setDevice,
  setCall,
  setCallToNumber,
  setCallName,
  setVoiceCallHistory,
  setShowSingleVoiceCallForDemo,
  setIsVoiceCallSelected,
} = callSlice.actions;

export default callSlice.reducer;
