import { GenericObject } from './UtilityTypes';

export type FilterType = {
  id: string;
  value: any;
  meta: FILTER_TYPES;
  filterVariant?: string;
};

export const enum FILTER_TYPES {
  RANGE = 'range',
  DATE_RANGE = 'date_range',
  DATE_RANGE_STRING = 'date_range_string',
  MATCH = 'match',
  MATCH_ID = 'match_id',
  REGEX = 'regex',
  SEQ_REGEX = 'seq_regex',
  FUZZY = 'fuzzy',
  IN = 'in',
  EXISTS = 'exists',
  MULTI_SELECT = 'multi-select',
}

export const enum FILTER_VALUE_TYPES {
  NUMBER = 'number',
  STRING = 'string',
  BOOLEAN = 'boolean',
  DATE = 'date',
}

export const enum FILTER_ID {
  CUSTOMER_ID = 'customers._id',
  FUZZY_SEARCH = 'fuzzySearch',
  CLIENT_ID = 'task.clientId',
  TASK_STATE = 'task.internalState,task.externalState',
  ASSIGNED_USER_ID = 'task.assignedUserId',
  DUE_AMOUNT = 'invoices.0.dueAmount',
  LAST_MESSAGE = 'task.lastMessageDate',
  LAST_EMAIL = 'lastEmail',
  P2P_DATE = 'actions.userEntity.date',
  FOLLOWUP_DATE = 'followupDate',
  LOCATION = 'location',
  BUCKET = 'bucket',
  STATUS = 'customers.0.status',
  PAYMENT_LINKS_OPENED = 'lastPaymentLinkOpenedAt',
  SLA_BREACHED = 'lastSlaBreachedAt',
  TICKETS = 'tickets',
}

export type FiltersChat = {
  id: FILTER_ID;
  value: GenericObject;
  meta: FILTER_TYPES;
};

export const PRIMARY_FILTERS = [FILTER_ID.CLIENT_ID, FILTER_ID.TASK_STATE, FILTER_ID.ASSIGNED_USER_ID];
