import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';
import { CommentType } from '@finance-ops/types';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';

export const commentsApi = createApi({
  reducerPath: 'commentsApi',
  baseQuery: baseQuery,
  tagTypes: ['Comments'],
  endpoints: builder => ({
    getComments: builder.query<CommentType[], string>({
      query: referenceId => `/comments/reference/${referenceId}`,
      providesTags: (result = [], error, referenceId) =>
        // Only provide tags if we have a valid referenceId
        referenceId
          ? [
              { type: 'Comments' as const, id: referenceId },
              ...result.map(comment => ({ type: 'Comments' as const, id: comment._id?.toString() })),
            ]
          : [],
    }),

    createComment: builder.mutation<
      CommentType,
      {
        content: string;
        commentedById: string;
        referenceId: string;
        referenceType: string;
        createdBy: string;
        attachments?: any[];
        customerId: string;
        clientId: string;
      }
    >({
      query: body => ({
        url: '/comments',
        method: 'POST',
        body,
      }),
      async onQueryStarted({ referenceId, ...patch }, { dispatch, queryFulfilled }) {
        console.log('onQueryStarted', patch);
        const newId = uuidv4();
        const optimisticComment: CommentType = {
          id: newId,
          createdAt: new Date(),
          updatedAt: new Date(),
          version: 1,
          referenceId,
          isInternal: false,
          ...patch,
        };

        dispatch(
          commentsApi.util.updateQueryData('getComments', referenceId, draft => {
            draft.unshift(optimisticComment);
          }),
        );

        try {
          const { data } = await queryFulfilled;
          dispatch(
            commentsApi.util.updateQueryData('getComments', referenceId, draft => {
              const index = draft.findIndex(c => c._id?.toString() === optimisticComment._id?.toString());
              if (index !== -1) draft[index] = data;
            }),
          );
        } catch {
          dispatch(
            commentsApi.util.updateQueryData('getComments', referenceId, draft => {
              const index = draft.findIndex(c => c._id?.toString() === optimisticComment._id?.toString());
              if (index !== -1) draft.splice(index, 1);
            }),
          );
          toast.error('Failed to add comment');
        }
      },
      invalidatesTags: (result, error, { referenceId }) => [{ type: 'Comments', id: referenceId }],
    }),

    updateComment: builder.mutation<
      CommentType,
      {
        id: string;
        content: string;
        attachments?: any[];
        referenceId: string;
      }
    >({
      query: ({ id, ...patch }) => ({
        url: `/comments/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      async onQueryStarted({ id, referenceId, ...patch }, { dispatch, queryFulfilled }) {
        // Optimistically update the comment
        dispatch(
          commentsApi.util.updateQueryData('getComments', referenceId, draft => {
            const comment = draft.find(c => c._id?.toString() === id);
            if (comment) {
              Object.assign(comment, {
                ...comment, // Keep existing fields
                ...patch, // Apply updates
                updatedAt: new Date(),
              });
            }
          }),
        );

        try {
          const { data } = await queryFulfilled;
          // Update with server response
          dispatch(
            commentsApi.util.updateQueryData('getComments', referenceId, draft => {
              const index = draft.findIndex(c => c._id?.toString() === id);
              if (index !== -1) draft[index] = data;
            }),
          );
        } catch {
          // Revert on error
          dispatch(
            commentsApi.util.updateQueryData('getComments', referenceId, draft => {
              const index = draft.findIndex(c => c._id?.toString() === id);
              if (index !== -1) draft.splice(index, 1);
            }),
          );
          toast.error('Failed to update comment');
        }
      },
      invalidatesTags: (result, error, { referenceId }) => [{ type: 'Comments' as const, id: referenceId }],
    }),

    deleteComment: builder.mutation<void, { id: string; referenceId: string }>({
      query: ({ id }) => ({
        url: `/comments/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id, referenceId }, { dispatch, queryFulfilled }) {
        // Store the comment before deletion
        let deletedComment: CommentType | undefined;

        dispatch(
          commentsApi.util.updateQueryData('getComments', referenceId, draft => {
            const index = draft.findIndex(c => c._id?.toString() === id);
            if (index !== -1) {
              deletedComment = draft[index];
              draft.splice(index, 1);
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          if (deletedComment) {
            dispatch(
              commentsApi.util.updateQueryData('getComments', referenceId, draft => {
                draft.push(deletedComment!);
              }),
            );
          }
          toast.error('Failed to delete comment');
        }
      },
      invalidatesTags: (result, error, { referenceId }) => [{ type: 'Comments' as const, id: referenceId }],
    }),
  }),
});

export const { useGetCommentsQuery, useCreateCommentMutation, useUpdateCommentMutation, useDeleteCommentMutation } =
  commentsApi;

export const selectCommentsByReferenceId = (referenceId: string) =>
  commentsApi.endpoints.getComments.select(referenceId);
