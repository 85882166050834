import { ACTION_CENTER_STATUS_TYPES, ACTION_TYPES, SEARCH_PARAMS } from '@finance-ops/constants';
import { ActionGetCountsResponseType, ActionsExtensionType, isActionTypeP2PAction } from '@finance-ops/types';
import {
  EntityId,
  EntityState,
  PayloadAction,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '.';

const actionsEntityAdapter = createEntityAdapter<ActionsExtensionType>({
  selectId: entity => entity.id,
  sortComparer: (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
});

interface ActionsState extends EntityState<ActionsExtensionType> {
  selectedAction: ActionsExtensionType | null;
  selectedActionId: string;
  loading: boolean;
  loadingComments: boolean;
  actionCenterCounts: { label: ACTION_CENTER_STATUS_TYPES; value: number }[];
  selectedParameters: string[];
}

const initialState: ActionsState = {
  ...actionsEntityAdapter.getInitialState(),
  selectedAction: null,
  selectedActionId: '',
  loading: false,
  loadingComments: false,
  actionCenterCounts: [
    { label: ACTION_CENTER_STATUS_TYPES.OPEN, value: 0 },
    { label: ACTION_CENTER_STATUS_TYPES.IN_PROGRESS, value: 0 },
    { label: ACTION_CENTER_STATUS_TYPES.CLOSED, value: 0 },
    { label: ACTION_CENTER_STATUS_TYPES.RESOLVED, value: 0 },
  ],
  selectedParameters: [
    SEARCH_PARAMS.TITLE,
    SEARCH_PARAMS.DESC,
    SEARCH_PARAMS.NAME,
    SEARCH_PARAMS.EMAIL,
    SEARCH_PARAMS.CONTACT_NUMBER,
    SEARCH_PARAMS.INVOICE_ID,
    SEARCH_PARAMS.PATIENT_ID,
    SEARCH_PARAMS.LOCATION,
  ],
};

const actionsSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    setActions: actionsEntityAdapter.setAll,
    setLoadingActions: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    deleteOneAction: actionsEntityAdapter.removeOne,
    deleteActionCenterActions: state => {
      state.loading = true;

      const idsToRemove = Object.values(state.entities)
        .filter(
          entity =>
            entity &&
            (entity.type === ACTION_TYPES.ESCALATION ||
              entity.type === ACTION_TYPES.DISPUTE ||
              entity.type === ACTION_TYPES.MANUAL_INTERVENTION),
        )
        .map(entity => entity && entity.id);

      if (idsToRemove && idsToRemove.length > 0) {
        actionsEntityAdapter.removeMany(state, idsToRemove as EntityId[]);
      }
    },
    upsertOneAction: actionsEntityAdapter.upsertOne,
    upsertManyActions: (state, action: PayloadAction<ActionsExtensionType[]>) => {
      actionsEntityAdapter.upsertMany(state, action.payload);
      state.loading = false;
    },
    upsertActions: (state, action: PayloadAction<ActionsExtensionType[]>) => {
      actionsEntityAdapter.upsertMany(state, action.payload);
    },
    setActionCenterCounts: (state, action: PayloadAction<ActionGetCountsResponseType[]>) => {
      state.actionCenterCounts = action.payload.map(item => ({
        label: item.label,
        value: item.value,
      }));
    },
    updateOneAction: actionsEntityAdapter.updateOne,
    setSelectedAction: (state, action: PayloadAction<ActionsExtensionType>) => {
      state.selectedAction = action.payload;
    },
    setSelectedActionId: (state, action: PayloadAction<string>) => {
      state.selectedActionId = action.payload;
    },
    resetActions: actionsEntityAdapter.removeAll,
    setActionsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSelectedParameters: (state, action: PayloadAction<string[]>) => {
      state.selectedParameters = action.payload;
    },
  },
});

export const actionsSelectors = actionsEntityAdapter.getSelectors((state: RootState) => state.actions);
export const loadingActions = (state: RootState) => state.actions.loading;

export const selectActionsByCustomerId = createSelector([actionsSelectors.selectAll, (_, id) => id], (actions, id) => {
  return actions.filter(action => action.customerId === id);
});

export const selectP2PByCustomerId = createSelector([actionsSelectors.selectAll, (_, id) => id], (actions, id) => {
  return actions
    .filter(action => action.customerId === id && action.type === ACTION_TYPES.P2P)
    .sort((a, b) => {
      if (!isActionTypeP2PAction(a) || !isActionTypeP2PAction(b)) return 0;
      return new Date(b.userEntity.date as Date).getTime() - new Date(a.userEntity.date as Date).getTime();
    });
});

export const selectActionCenterActions = createSelector([actionsSelectors.selectAll, (_, id) => id], (actions, id) =>
  actions.filter(action => action.type === id),
);

export const selectActionsByCommunicationId = createSelector(
  [actionsSelectors.selectAll, (_, id) => id],
  (actions, id) => {
    return actions.filter(action => action.communicationId === id);
  },
);

export const selectActionsByType = createSelector([actionsSelectors.selectAll, (_, type) => type], (actions, type) => {
  return actions.filter(action => action.type === type);
});

const getSelectedActionId = (state: RootState) => state.actions.selectedActionId;

// Output selector
export const getSelectedAction = createSelector(
  [actionsSelectors.selectAll, getSelectedActionId],
  (actions, selectedActionId) => actions.find(action => action.id === selectedActionId),
);

// Output selector
export const getActionById = createSelector([actionsSelectors.selectAll, (state, id) => id], (actions, id) => {
  return actions.find(action => action.id === id);
});

export const {
  setActions,
  setActionCenterCounts,
  setLoadingActions,
  deleteOneAction,
  deleteActionCenterActions,
  upsertOneAction,
  upsertManyActions,
  upsertActions,
  updateOneAction,
  setSelectedAction,
  resetActions,
  setActionsLoading,
  setSelectedActionId,
  setSelectedParameters,
} = actionsSlice.actions;

export default actionsSlice.reducer;
