import axios from 'axios';
import type { Log } from './types';

// Add event emitter for log updates
type LogUpdateListener = () => void;
const logUpdateListeners: Set<LogUpdateListener> = new Set();

export function subscribeToLogUpdates(listener: LogUpdateListener): () => void {
  logUpdateListeners.add(listener);
  return () => {
    logUpdateListeners.delete(listener);
  };
}

function notifyLogUpdate() {
  // Add small delay to ensure DB write is complete
  setTimeout(() => {
    logUpdateListeners.forEach(listener => {
      try {
        listener();
      } catch (error) {
        console.error('[DevTools] Listener error:', error);
      }
    });
  }, 50);
}

function parseUrl(urlString: string) {
  try {
    const url = new URL(urlString);
    const pathname = url.pathname;
    const searchParams = Object.fromEntries(url.searchParams);
    const shortPath = pathname.split('/').filter(Boolean).join('/');

    return {
      shortUrl: '/' + shortPath,
      fullUrl: urlString,
      queryParams: Object.keys(searchParams).length ? JSON.stringify(searchParams) : undefined,
    };
  } catch {
    return {
      shortUrl: urlString,
      fullUrl: urlString,
      queryParams: undefined,
    };
  }
}

function isIgnoredPath(url: string): boolean {
  return !url.includes('/app-gateway');
}

// Increase truncation limit for development tools
const MAX_RESPONSE_SIZE = 50 * 1024; // Reduced to 50KB for preview

export function processResponse(response: string): { truncated: string; size: number } {
  // Use Blob for more efficient size calculation
  const size = new Blob([response]).size;

  // Early return for small responses
  if (size <= MAX_RESPONSE_SIZE) {
    return { truncated: response, size };
  }

  try {
    // Use streaming JSON parser for large payloads
    const parsed = JSON.parse(response);

    // For arrays, keep first few elements and metadata
    if (Array.isArray(parsed)) {
      const truncated = {
        _type: 'array',
        _total: parsed.length,
        _preview: parsed.slice(0, 5),
        _truncated: `${parsed.length - 5} more items`,
      };
      return { truncated: JSON.stringify(truncated), size };
    }

    // For objects, keep important fields and metadata
    const truncated = {
      _type: 'object',
      _total: Object.keys(parsed).length,
      _preview: Object.fromEntries(Object.entries(parsed).slice(0, 10)),
      _truncated: `${Object.keys(parsed).length - 10} more fields`,
    };

    return { truncated: JSON.stringify(truncated), size };
  } catch {
    // For non-JSON responses, truncate with size info
    return {
      truncated: `${response.substring(0, MAX_RESPONSE_SIZE)}\n... (truncated ${(size / 1024).toFixed(1)}KB total)`,
      size,
    };
  }
}

let worker: Worker | null = null;

// Initialize worker
if (typeof window !== 'undefined') {
  try {
    worker = new Worker(new URL('./devtools-logger.worker.ts', import.meta.url));
    worker.addEventListener('message', event => {
      if (event.data.type === 'log_saved') {
        notifyLogUpdate();
      }
    });
  } catch (error) {
    console.error('[DevTools] Worker initialization error:', error);
  }
}

export function queueLog(log: Log) {
  if (!worker) {
    console.warn('[DevTools] Worker not initialized');
    return;
  }

  try {
    worker.postMessage({ type: 'process_log', payload: log });
  } catch (error) {
    console.error('[DevTools] Error sending log to worker:', error);
  }
}

export function setupHttpInterceptor() {
  if (typeof window === 'undefined') return;

  const originalFetch = window.fetch;
  window.fetch = async (...args) => {
    const [url, config] = args;
    const urlString = typeof url === 'string' ? url : url instanceof Request ? url.url : url.toString();
    const method =
      (typeof url === 'string' ? config?.method : url instanceof Request ? url.method : 'N/A')?.toUpperCase() || 'N/A';

    if (isIgnoredPath(urlString)) {
      return originalFetch(...args);
    }

    const startTime = Date.now();
    try {
      const response = await originalFetch(...args);

      if (!response.bodyUsed) {
        const responseForLog = response.clone();
        setTimeout(() => {
          createFetchLog(responseForLog, startTime, urlString, {
            ...config,
            method,
          })
            .then(queueLog)
            .catch(error => console.error('[DevTools] Error creating fetch log:', error));
        }, 0);
      }

      return response;
    } catch (error: any) {
      setTimeout(() => {
        queueLog(
          createErrorLog(error, startTime, urlString, {
            ...config,
            method: method.toUpperCase(),
          }),
        );
      }, 0);
      throw error;
    }
  };

  // Axios interceptor
  axios.interceptors.request.use(
    config => {
      config.metadata = { startTime: Date.now() };
      return config;
    },
    error => {
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    response => {
      if (!isIgnoredPath(response.config.url || '')) {
        const duration = Date.now() - (response.config.metadata?.startTime || Date.now());
        setTimeout(() => {
          createAxiosLog({ ...response, duration }).then(queueLog);
        }, 0);
      }
      return response;
    },
    error => {
      if (error.response && !isIgnoredPath(error.config.url || '')) {
        const duration = Date.now() - (error.config.metadata?.startTime || Date.now());
        setTimeout(() => {
          queueLog(createAxiosErrorLog({ ...error, duration }));
        }, 0);
      }
      return Promise.reject(error);
    },
  );
}

async function createFetchLog(response: Response, startTime: number, url: string, config?: RequestInit): Promise<Log> {
  const parsedUrl = parseUrl(url);
  let responseBody: string | undefined;
  let requestBody: string | undefined;

  try {
    // Capture request body
    if (config?.body) {
      requestBody = typeof config.body === 'string' ? config.body : JSON.stringify(config.body);
    }

    // Capture response body
    if (!response.bodyUsed) {
      const responseClone = response.clone();
      responseBody = await responseClone.text();
    }

    return {
      type: 'http',
      method: config?.method || 'N/A',
      url: parsedUrl.shortUrl,
      full_url: parsedUrl.fullUrl,
      query_params: parsedUrl.queryParams,
      request_body: requestBody,
      response_body: responseBody,
      status: response.status,
      duration: Date.now() - startTime,
      timestamp: new Date().toISOString(),
    };
  } catch (error) {
    return {
      type: 'http',
      method: config?.method || 'N/A',
      url: parsedUrl.shortUrl,
      full_url: parsedUrl.fullUrl,
      query_params: parsedUrl.queryParams,
      request_body: requestBody,
      response_body: '{ "_error": "Error processing response" }',
      status: response.status,
      duration: Date.now() - startTime,
      timestamp: new Date().toISOString(),
    };
  }
}

function createErrorLog(error: any, startTime: number, url: string, config?: RequestInit): Log {
  const parsedUrl = parseUrl(url);
  return {
    type: 'http',
    method: config?.method || 'N/A',
    url: parsedUrl.shortUrl,
    full_url: parsedUrl.fullUrl,
    query_params: parsedUrl.queryParams,
    response_body: JSON.stringify(error),
    status: error.status || 500,
    duration: Date.now() - startTime,
    timestamp: new Date().toISOString(),
  };
}

async function createAxiosLog(response: any): Promise<Log> {
  const parsedUrl = parseUrl(response.config.url);
  return {
    type: 'http',
    method: response.config.method?.toUpperCase() || 'N/A',
    url: parsedUrl.shortUrl,
    full_url: parsedUrl.fullUrl,
    query_params: parsedUrl.queryParams,
    request_body: JSON.stringify(response.config.data || null),
    response_body: JSON.stringify(response.data || null),
    status: response.status,
    duration: response.duration,
    timestamp: new Date().toISOString(),
  };
}

function createAxiosErrorLog(error: any): Log {
  const parsedUrl = parseUrl(error.config?.url || '');
  return {
    type: 'http',
    method: error.config?.method?.toUpperCase() || 'N/A',
    url: parsedUrl.shortUrl,
    full_url: error.config?.url,
    query_params: parsedUrl.queryParams,
    request_body: JSON.stringify(error.config?.data || null),
    response_body: JSON.stringify(error.response?.data || error.message),
    status: error.response?.status || 500,
    duration: error.duration || 0,
    timestamp: new Date().toISOString(),
  };
}

// Add at the top of the file
declare module 'axios' {
  export interface InternalAxiosRequestConfig {
    metadata?: {
      startTime: number;
    };
  }
}
