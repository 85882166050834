import { EntityChangePayload, EntityChangeType } from '@finance-ops/types';
import { store } from '../store';
import { actionsApi } from '../store/api/actionsApi';
import { commentsApi } from '../store/api/commentsApi';
const apiMap = {
  comments: commentsApi,
  actions: actionsApi,
} as const;

interface QueryState {
  endpointName: string;
  originalArgs: any;
}

// Add interface for items that can have either id or _id
interface EntityItem {
  id?: string;
  _id?: string;
}

export function handleEntityChange<T>(payload: EntityChangePayload<any>) {
  const { entity, type, data } = payload;
  const api = apiMap[entity as keyof typeof apiMap];
  if (!api) return;

  const queries = store.getState()[api.reducerPath].queries;
  if (!queries) return;

  Object.entries(queries).forEach(([queryKey, query]) => {
    const typedQuery = query as QueryState;
    const endpoint = typedQuery.endpointName;

    if (!(endpoint in api.endpoints)) return;

    store.dispatch(
      (api.util.updateQueryData as any)(endpoint, typedQuery.originalArgs, (draft: any) => {
        // Handle paginated data
        if (draft?.data) {
          const items = draft.data;
          let index: number;

          switch (type) {
            case EntityChangeType.UPDATED:
              index = items.findIndex((item: EntityItem) => item._id === data._id || item.id === data.id);
              if (index !== -1) items[index] = data;
              break;

            case EntityChangeType.CREATED:
              items.unshift(data);
              // FIXME: total count increment should respect filters/types to avoid wrong count
              // if (typeof draft.total === 'number') {
              //   draft.total += 1;
              // }
              break;

            case EntityChangeType.DELETED:
              index = items.findIndex((item: EntityItem) => item._id === data._id || item.id === data.id);
              if (index !== -1) {
                items.splice(index, 1);
                if (typeof draft.total === 'number') {
                  draft.total -= 1;
                }
              }
              break;
          }
        } else if (Array.isArray(draft)) {
          let index: number;
          switch (type) {
            case EntityChangeType.UPDATED:
              index = draft.findIndex(item => item._id === data._id || item.id === data.id);
              if (index !== -1) draft[index] = data;
              break;

            case EntityChangeType.CREATED:
              draft.unshift(data);
              break;

            case EntityChangeType.DELETED:
              index = draft.findIndex(item => item._id === data._id || item.id === data.id);
              if (index !== -1) draft.splice(index, 1);
              break;
          }
        } else if (draft?.id === data.id || draft?._id === data._id) {
          Object.assign(draft, data);
        }
      }),
    );
  });
}
