import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export enum NewWorkflowPageNamesList {
  FILTER = 'filter',
  LOADER = 'loader',
  CREATE = 'create',
  LANDING = 'landing',
  DASHBOARD = 'dashboard',
  VIEW = 'view',
}

export enum NewWorkflowType {
  REMINDER = 'REMINDER',
  AUTOPILOT = 'AUTOPILOT',
  COPILOT = 'COPILOT',
  AUTOPILOT_COPILOT = 'AUTOPILOT_COPILOT',
}

export enum NewWorkflowFilterType {
  CUSTOM = 'customCondition',
  P2P = 'p2pReminders',
  FOLLOW_UP = 'followUpReminders',
  COLLECTION = 'easeOfCollections',
  WORKLIST = 'worklistRank',
}

export enum NewWorkflowFilterFieldType {
  P2P = 'promiseToPayDate',
}

export enum NewWorkflowFilterFieldOperatorType {
  greaterThan = 'greater than equals to',
  lessThan = 'less than equals to',
}

export enum NewWorkflowReminderType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  BOTH = 'BOTH',
}

export enum NewWorkflowInvoiceType {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  BOTH = 'BOTH',
}
export enum NewWorkflowTimingsType {
  DEMAND = 'onDemand',
  BATCH = 'scheduled',
}

export interface NewWorkflowReminder {
  type: NewWorkflowReminderType;
  value: any;
}

export interface NewWorkflowInvoice {
  type: NewWorkflowInvoiceType;
  value: any;
}

export interface NewWorkflowAction {
  intent: NewWorkflowActionIntent;
  name: string;
  order: number;
  communicationMedium: CommunicationMediumType[];
  delay: number;
  messageTone?: string;
  messageTemplate?: string;
  id?: string;
}
export enum NewWorkflowActionIntent {
  reminder = 'sendReminder',
  invoice = 'sendInvoices',
}
export enum CommunicationMediumType {
  SMS = 'sms',
  EMAIL = 'email',
}

export interface RuleDefinationValue {
  fieldName: NewWorkflowFilterFieldType;
  operator: NewWorkflowFilterFieldOperatorType;
  condition: number;
}

export interface NewWorkflowExecutionTiming {
  day: string;
  time: string;
}

export interface NewWorkflowExecution {
  type: NewWorkflowTimingsType;
  scheduled: NewWorkflowExecutionTiming[];
}

export interface NewSingleWorkflow {
  name?: string;
  workflowId?: string;
  type?: NewWorkflowType;
  ruleDefinition?: {
    cohortType: NewWorkflowFilterType;
    and?: RuleDefinationValue[];
    or?: RuleDefinationValue[];
  };
  actions?: NewWorkflowAction[];
  execution?: NewWorkflowExecution;
}

interface NewWorkflowSlice {
  currentPage: NewWorkflowPageNamesList;
  currentNewWorkflow: NewSingleWorkflow;
  workflowList: NewSingleWorkflow[];
  selectedWorkflowId: string;
  isEditing: boolean;
  editingActionId: string;
  indexOfActionInView: number | undefined;
}

const initialState: NewWorkflowSlice = {
  currentPage: NewWorkflowPageNamesList.LANDING,
  currentNewWorkflow: {},
  workflowList: [],
  selectedWorkflowId: '',
  isEditing: false,
  editingActionId: '',
  indexOfActionInView: undefined,
};

const NewWorkflowSlice = createSlice({
  name: 'WorkflowState',
  initialState,
  reducers: {
    setCurrentNewWorkflowPageName: (state, action: PayloadAction<NewWorkflowPageNamesList>) => {
      state.currentPage = action.payload;
    },
    setNewCurrentWorkflow: (state, action: PayloadAction<NewSingleWorkflow>) => {
      state.currentNewWorkflow = action.payload;
    },
    setNewWorkflowList: (state, action: PayloadAction<NewSingleWorkflow[]>) => {
      state.workflowList = action.payload;
    },
    setSelectedNewWorkflow: (state, action: PayloadAction<string>) => {
      state.selectedWorkflowId = action.payload;
    },
    setIsEditingNewWorkflow: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
    setEditingActionV2Id: (state, action: PayloadAction<string>) => {
      state.editingActionId = action.payload;
    },
    setIndexOfActionInView: (state, action: PayloadAction<number | undefined>) => {
      state.indexOfActionInView = action.payload;
    },
  },
});

export const {
  setCurrentNewWorkflowPageName,
  setNewCurrentWorkflow,
  setNewWorkflowList,
  setSelectedNewWorkflow,
  setIsEditingNewWorkflow,
  setEditingActionV2Id,
  setIndexOfActionInView,
} = NewWorkflowSlice.actions;

export default NewWorkflowSlice.reducer;
