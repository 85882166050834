import { NOTIFICATION_INDICATOR_TASK_EVENTS } from '@finance-ops/constants';
import { NOTIFICATION_STATUS, NotificationGetAllDtoType, NotificationGetPreviousDtoType } from '@finance-ops/types';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../store';
import { notificationsApi } from '../store/api/notificationsApi';
import {
  notificationsSelectors,
  selectAllReadNotifications,
  selectAllUnreadNotifications,
  selectUnHydratedNotificationIds,
  upsertManyNotifications,
} from '../store/notification';
import { getDatePastDays } from '../utils/date-utils';

export const useNotifications = () => {
  const dispatch = useDispatch<AppDispatch>();
  const allNotifications = useSelector(notificationsSelectors.selectAll);
  const allUnreadNotifications = useSelector(selectAllUnreadNotifications);
  const allReadNotifications = useSelector(selectAllReadNotifications);
  const unHydratedNotificationIds = useSelector(selectUnHydratedNotificationIds);
  const [unHydratedTaskIds, setUnHydratedTaskIds] = useState<string[]>([]);
  const [updateNotificationStatusMutation] = notificationsApi.useUpdateNotificationStatusMutation();
  const [fetchNotifications] = notificationsApi.useLazyFetchNotificationsQuery();
  const [fetchPrevious] = notificationsApi.useLazyFetchPreviousNotificationsQuery();

  useEffect(() => {
    const taskIds = allNotifications
      .filter(notification => unHydratedNotificationIds.includes(notification.id))
      .map(notification => notification.data?.tasks?.id)
      .filter(id => id !== undefined) as string[];
    setUnHydratedTaskIds(taskIds);
  }, [unHydratedNotificationIds, allNotifications]);

  const updateNotificationsAsRead = useCallback(
    async (ids: string[]) => {
      if (ids.length === 0) return;

      try {
        await updateNotificationStatusMutation({ ids, status: NOTIFICATION_STATUS.READ }).unwrap();
      } catch (error) {
        console.error('Failed to update notification status:', error);
      }
    },
    [updateNotificationStatusMutation],
  );

  const getNotifications = useCallback(
    async (userId: string) => {
      try {
        const request: NotificationGetAllDtoType = {
          userId,
          pageNumber: 0,
          pageSize: 100,
          status: NOTIFICATION_STATUS.UNREAD,
          events: NOTIFICATION_INDICATOR_TASK_EVENTS,
        };

        const result = await fetchNotifications(request).unwrap();
        dispatch(upsertManyNotifications(result));
      } catch (error) {
        console.error('Failed to fetch notifications:', error);
      }
    },
    [dispatch, fetchNotifications],
  );

  const getPreviousNotifications = useCallback(
    debounce(async (userId: string, fromDate?: Date) => {
      try {
        if (fromDate && new Date(fromDate).getTime() < getDatePastDays(3).getTime()) {
          return;
        }

        const request: NotificationGetPreviousDtoType = {
          userId,
          fromDate,
          pageSize: 20,
          status: NOTIFICATION_STATUS.UNREAD,
          events: NOTIFICATION_INDICATOR_TASK_EVENTS,
        };

        const result = await fetchPrevious(request).unwrap();
        dispatch(upsertManyNotifications(result));
      } catch (error) {
        console.error('Failed to fetch previous notifications:', error);
      }
    }, 5000),
    [fetchPrevious],
  );

  const getTaskIdsFromNotificationIds = useCallback(
    (notificationIds: string[]) => {
      return allNotifications
        .filter(notification => notificationIds.includes(notification.id))
        .map(notification => notification.data?.tasks?.id);
    },
    [allNotifications],
  );

  return {
    allNotifications,
    allReadNotifications,
    allUnreadNotifications,
    updateNotificationsAsRead,
    getNotifications,
    getPreviousNotifications,
    unHydratedNotificationIds,
    getTaskIdsFromNotificationIds,
    unHydratedTaskIds,
  };
};
