import {
  CustomerContext,
  CustomerContextCountPerStateType,
  CustomerContextGet,
  FILTER_ID,
  FiltersChat,
  PRIMARY_FILTERS,
  Sort,
  TASK_STATE,
  TaskType,
} from '@finance-ops/types';
import { EntityState, PayloadAction, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

const defaultPaginationState = {
  page: 1,
  pageSize: 50,
};

interface CustomerContextPaginationType {
  page: number;
  pageSize: number;
}

export enum ChatCommunicationTypes {
  sms = 'sms',
  emails = 'emails',
  calls = 'calls',
}

export enum CHAT_TAB {
  UNREAD = 'UNREAD',
  ALL = 'ALL',
  ASSIGNED = 'ASSIGNED',
}

const defaultCustomerContextCountByState: CustomerContextCountPerStateType = {
  [TASK_STATE.WORKLIST]: 0,
  [TASK_STATE.ASSIGNED]: 0,
  [TASK_STATE.REVIEW]: 0,
  [TASK_STATE.COMPLETED]: 0,
  [TASK_STATE.AUTOPILOT]: 0,
  [TASK_STATE.UNASSIGNED]: 0,
  [TASK_STATE.EXTERNAL]: 0,
};
export interface CustomerContextPaginationPayload {
  stateFilter?: TASK_STATE;
  selectedClientId: string | null;
  query?: string | null;
  assignedUserId?: string | null;
  enabledSecondaryFiltersOrSort?: boolean;
  requestHash?: string | null;
}

interface CustomerContextStateItem {
  filteredAndSortedCustomerContexts: CustomerContext[];
  selectedTaskId?: string;
  selectedCustomerId?: string;
  selectedCustomerContextId?: string;
  hydratedIds: string[];
  fetched: boolean;
  customerContextLoading: boolean;
  paginationLoading: boolean;
  taskState: TASK_STATE | undefined;
  assignedUserId: string | null | undefined;
  selectChatTab: CHAT_TAB;
  pagination: { [key: string]: CustomerContextPaginationType };
  totalCustomerContextCount: number;
  customerSearchQuery?: string;
  paginationKey: string | null;
  communicationType: ChatCommunicationTypes;
  showChatSettings: boolean;
  isUserEmailSetup: boolean;
  addNewMail: boolean;
  currentFilters: FiltersChat[]; // used for displaying the filters in the UI avoid race conditions when value is changed
  appliedFilters: FiltersChat[] | null; // used for storing the applied filters in current request
  appliedSort: Sort | null;
  requestHash: string | null;
  chatLoadingState: { [customerId: string]: boolean };
  customerContextCountByState: CustomerContextCountPerStateType;
  customerContextGetRequest?: CustomerContextGet;
  customerContextGetCountRequest?: CustomerContextGet;
}

interface CustomerContextState extends EntityState<CustomerContext>, CustomerContextStateItem {}

const customerContextEntityAdapter = createEntityAdapter<CustomerContext>({
  selectId: entity => entity.task.id,
});

export const customerContextSelectors = customerContextEntityAdapter.getSelectors<RootState>(
  state => state.customerContext,
);

const initialState: CustomerContextState = {
  ...customerContextEntityAdapter.getInitialState(),
  filteredAndSortedCustomerContexts: [],
  selectedTaskId: undefined,
  selectedCustomerId: undefined,
  selectedCustomerContextId: undefined,
  hydratedIds: [],
  fetched: false,
  customerContextLoading: true,
  paginationLoading: false,
  taskState: TASK_STATE.WORKLIST,
  assignedUserId: undefined,
  selectChatTab: CHAT_TAB.ALL,
  pagination: {},
  totalCustomerContextCount: 0,
  customerSearchQuery: undefined,
  paginationKey: null,
  communicationType: ChatCommunicationTypes.sms,
  showChatSettings: false,
  isUserEmailSetup: false,
  addNewMail: false,
  currentFilters: [],
  appliedFilters: null,
  appliedSort: null,
  requestHash: null,
  chatLoadingState: {},
  customerContextCountByState: defaultCustomerContextCountByState,
  customerContextGetRequest: undefined,
};

export const customerContextSlice = createSlice({
  name: 'customerContext',
  initialState,
  reducers: {
    upsertOneCustomerContext: (state, action) => {
      customerContextEntityAdapter.upsertOne(state, action.payload);
      // update the customercontext if present in filteredAndSortedCustomerContexts
      const index = state.filteredAndSortedCustomerContexts.findIndex(
        context => context.task.id === action.payload.task.id,
      );
      if (index !== -1) {
        state.filteredAndSortedCustomerContexts[index] = action.payload;
      }
    },
    updateOneCustomerContext: (state, action) => {
      customerContextEntityAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
      // update the customercontext if present in filteredAndSortedCustomerContexts
      const index = state.filteredAndSortedCustomerContexts.findIndex(
        context => context.task.id === action.payload.task.id,
      );
      if (index !== -1) {
        state.filteredAndSortedCustomerContexts[index] = action.payload;
      }
    },
    upsertManyCustomerContexts: (state, action: PayloadAction<CustomerContext[]>) => {
      // Collect all customerContexts not present in payload
      const existingContexts = Object.values(state.entities).filter(
        entity => !action.payload.some(payload => payload.task.id === entity?.task.id),
      );

      // Combine new contexts (at the front) with existing contexts and filter out undefined values
      const updatedContexts = [...existingContexts, ...action.payload].filter(
        (context): context is CustomerContext => context !== undefined,
      );

      // Use setAll to update the state with the new order
      customerContextEntityAdapter.setAll(state, updatedContexts);

      state.customerContextLoading = false;
    },
    upsertManyFilteredAndSortedCustomerContexts: (state, action: PayloadAction<CustomerContext[]>) => {
      // Collect all customerContexts not present in payload
      const existingContexts = Object.values(state.filteredAndSortedCustomerContexts).filter(
        entity => !action.payload.some(payload => payload.task.id === entity?.task.id),
      );

      // Combine new contexts (at the front) with existing contexts and filter out undefined values
      const updatedContexts = [...existingContexts, ...action.payload].filter(
        (context): context is CustomerContext => context !== undefined,
      );

      // Use setAll to update the state with the new order
      state.filteredAndSortedCustomerContexts = updatedContexts;

      state.customerContextLoading = false;
    },
    resetCustomerContext: () => {
      return initialState;
    },
    setSelectedCustomerId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedCustomerId = action.payload;
    },
    setAssignedAgentId: (state, action: PayloadAction<string | null | undefined>) => {
      state.assignedUserId = action.payload;
    },
    setSelectedCustomerContextId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedCustomerContextId = action.payload;
    },
    setSelectedTaskId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedTaskId = action.payload;
    },
    setCustomerContextPagination: (state, action: PayloadAction<string>) => {
      state.paginationLoading = true;
      const key = action.payload;
      state.paginationKey = key;
      const paginationState = state.pagination[key];
      if (paginationState) {
        state.pagination[key] = {
          ...paginationState,
          page: paginationState.page + 1,
        };
      } else {
        state.pagination[key] = defaultPaginationState;
      }
    },
    setCustomerSearchQuery: (state, action: PayloadAction<string>) => {
      state.customerSearchQuery = action.payload;
    },
    setCustomerContextCount: (state, action: PayloadAction<number>) => {
      state.totalCustomerContextCount = action.payload;
    },
    setTaskStateFilter: (state, action: PayloadAction<TASK_STATE>) => {
      state.taskState = action.payload;
    },
    setAssignedUserId: (state, action: PayloadAction<string | null>) => {
      state.assignedUserId = action.payload;
    },
    setCustomerContextPaginationLoading: (state, action: PayloadAction<boolean>) => {
      state.paginationLoading = action.payload;
    },
    setTaskSearchQuery: (state, action: PayloadAction<string>) => {
      state.customerSearchQuery = action.payload;
    },
    setHydratedCustomerContextIds: (state, action: PayloadAction<string[]>) => {
      const hydratedIds = new Set(state.hydratedIds);
      action.payload.forEach(id => hydratedIds.add(id));
      state.hydratedIds = Array.from(hydratedIds);
    },
    setCommunicationType: (state, action: PayloadAction<ChatCommunicationTypes>) => {
      state.communicationType = action.payload;
    },
    setShowChatSettings: (state, action: PayloadAction<boolean>) => {
      state.showChatSettings = action.payload;
    },
    setIsUserEmailSetup: (state, action: PayloadAction<boolean>) => {
      state.isUserEmailSetup = action.payload;
    },
    setAddNewMail: (state, action: PayloadAction<boolean>) => {
      state.addNewMail = action.payload;
    },
    applyFilter: (state, action: PayloadAction<FiltersChat>) => {
      // check if filter already exists in appliedFilters array with same id, if so, update it, otherwise add it
      if (state.appliedFilters === null) {
        // If appliedFilters is null, initialize it with the new filter
        state.appliedFilters = [action.payload];
      } else {
        const index = state.appliedFilters.findIndex(filter => filter.id === action.payload.id);
        if (index !== -1) {
          // Update existing filter
          state.appliedFilters[index] = action.payload;
        } else {
          // Add new filter
          state.appliedFilters.push(action.payload);
        }
      }
    },
    removeAppliedFilter: (state, action: PayloadAction<FILTER_ID>) => {
      const appliedFilters = state.appliedFilters?.filter(filter => filter.id !== action.payload) ?? null;
      if (appliedFilters) {
        state.appliedFilters = appliedFilters;
      }
    },
    setCurrentFilter: (state, action: PayloadAction<FiltersChat>) => {
      const existingFilter = state.currentFilters.find(filter => filter.id === action.payload.id);
      if (existingFilter) {
        existingFilter.value = action.payload.value;
      } else {
        state.currentFilters = [...state.currentFilters, action.payload];
      }
    },
    resetFilterAndSort: state => {
      state.filteredAndSortedCustomerContexts = [];
      const primaryAppliedFilters = state.appliedFilters?.filter(filter => PRIMARY_FILTERS.includes(filter.id)) ?? null;
      state.appliedFilters = primaryAppliedFilters;
      state.appliedSort = null;
      state.currentFilters = [];
      // delete all pagination keys which have enabledSecondaryFiltersOrSort set to true
      Object.keys(state.pagination).forEach(key => {
        try {
          const parsedKey = JSON.parse(key);
          if (parsedKey.enabledSecondaryFiltersOrSort) {
            delete state.pagination[key];
          }
        } catch (error) {
          console.error('Error parsing pagination key:', error);
        }
      });
    },
    resetSearchPagination: state => {
      Object.keys(state.pagination).forEach(key => {
        try {
          const parsedKey = JSON.parse(key);
          if (parsedKey.query?.length > 0) {
            delete state.pagination[key];
          }
        } catch (error) {
          console.error('Error parsing pagination key:', error);
        }
      });
    },
    upsertCustomerContextTask: (state, action: PayloadAction<TaskType>) => {
      const taskId = action.payload.id;
      const contextId = Object.keys(state.entities).find(id => state.entities[id]?.task.id === taskId);

      if (contextId) {
        customerContextEntityAdapter.updateOne(state, {
          id: contextId,
          changes: { task: action.payload },
        });
      }
    },
    setSort(state, action: PayloadAction<Sort | null>) {
      state.appliedSort = structuredClone(action.payload);
    },
    setChatLoadingState: (state, action: PayloadAction<{ customerId: string; isLoading: boolean }>) => {
      const { customerId, isLoading } = action.payload;
      state.chatLoadingState[customerId] = isLoading;
    },
    setRequestHash: (state, action: PayloadAction<string | null>) => {
      state.requestHash = action.payload;
    },
    setCustomerContextCountByState: (state, action: PayloadAction<CustomerContextCountPerStateType>) => {
      state.customerContextCountByState = {
        ...defaultCustomerContextCountByState,
        ...action.payload,
      };
    },
    resetCustomerContextCountByState: state => {
      state.customerContextCountByState = defaultCustomerContextCountByState;
    },
    setChatTab: (state, action: PayloadAction<CHAT_TAB>) => {
      state.selectChatTab = action.payload;
    },
    setCustomerContextGetRequest: (state, action: PayloadAction<CustomerContextGet | undefined>) => {
      state.customerContextGetRequest = action.payload;
    },
    setCustomerContextGetCountRequest: (state, action: PayloadAction<CustomerContextGet | undefined>) => {
      state.customerContextGetCountRequest = action.payload;
    },
  },
});

export const selectedCustomerContext = createSelector(
  (state: RootState) => state.customerContext.entities,
  (state: RootState) => state.customerContext.filteredAndSortedCustomerContexts,
  (state: RootState) => state.customerContext.selectedCustomerContextId,
  (state: RootState) => state.customerContext.requestHash,
  (entities, filteredAndSortedCustomerContexts, selectedCustomerContextId, requestHash) => {
    const source = requestHash ? filteredAndSortedCustomerContexts : Object.values(entities);
    if (!selectedCustomerContextId) return undefined;
    return source.find(entity => entity?.task.id === selectedCustomerContextId);
  },
);

export const selectSelectedCustomer = createSelector(
  (state: RootState) => state.customerContext.entities,
  (state: RootState) => state.customerContext.filteredAndSortedCustomerContexts,
  (state: RootState) => state.customerContext.selectedCustomerContextId,
  (state: RootState) => state.customerContext.selectedCustomerId,
  (state: RootState) => state.customerContext.requestHash,
  (entities, filteredAndSortedCustomerContexts, selectedCustomerContextId, selectedCustomerId, requestHash) => {
    const source = requestHash ? filteredAndSortedCustomerContexts : Object.values(entities);
    if (selectedCustomerContextId && selectedCustomerId)
      return source
        .find(entity => entity?.task.id === selectedCustomerContextId)
        ?.customers.find(c => c._id.toString() === selectedCustomerId);
    return undefined;
  },
);

export const unHydratedCustomerContexts = createSelector(
  (state: RootState) => state.customerContext.entities,
  (state: RootState) => state.customerContext.hydratedIds,
  (state: RootState) => state.customerContext.filteredAndSortedCustomerContexts,
  (state: RootState) => state.customerContext.requestHash,
  (entities, hydratedIds, filteredAndSortedCustomerContexts, requestHash) => {
    const source = requestHash ? filteredAndSortedCustomerContexts : Object.values(entities);
    return source.filter(entity => entity && !hydratedIds.includes(entity.task.id));
  },
);

export const selectChatLoadingState = createSelector(
  (state: RootState) => state.customerContext.chatLoadingState,
  (state: RootState, customerId: string) => customerId,
  (chatLoadingState, customerId) => chatLoadingState[customerId] ?? false,
);

export const {
  applyFilter,
  removeAppliedFilter,
  resetCustomerContext,
  resetCustomerContextCountByState,
  resetFilterAndSort,
  resetSearchPagination,
  setAddNewMail,
  setAssignedAgentId,
  setChatLoadingState,
  setChatTab,
  setCommunicationType,
  setCurrentFilter,
  setCustomerContextCount,
  setCustomerContextCountByState,
  setCustomerContextGetCountRequest,
  setCustomerContextGetRequest,
  setCustomerContextPagination,
  setCustomerContextPaginationLoading,
  setCustomerSearchQuery,
  setHydratedCustomerContextIds,
  setIsUserEmailSetup,
  setRequestHash,
  setSelectedCustomerContextId,
  setSelectedCustomerId,
  setShowChatSettings,
  setSort,
  setTaskStateFilter,
  updateOneCustomerContext,
  upsertCustomerContextTask,
  upsertManyCustomerContexts,
  upsertManyFilteredAndSortedCustomerContexts,
  upsertOneCustomerContext,
} = customerContextSlice.actions;

export default customerContextSlice.reducer;
