import IconStyles from 'apps/webapp/src/types/iconStyles';

const OpenedEmailIcon = (styles?: IconStyles) => {
  const color = styles?.color || '#6461F3';
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='17' viewBox='0 0 17 17' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.13456 15.9522C2.84686 16.0236 0.931981 14.2405 0.852081 11.9645V7.97564L0.846713 5.98229C0.708811 5.26014 1.07502 4.53574 1.73993 4.21542L6.08253 1.63774C7.4772 0.787419 9.23336 0.787419 10.628 1.63774L14.9706 4.21862C15.6355 4.53895 16.0017 5.26334 15.8638 5.9855L15.8585 7.97991V11.9677C15.7768 14.2425 13.8625 16.0237 11.576 15.9522H5.13456Z'
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.09806 5.55008C0.859359 5.41125 0.553303 5.49221 0.414468 5.73091C0.275633 5.96962 0.356593 6.27567 0.595297 6.41451L1.09806 5.55008ZM6.11792 9.04816L6.37364 8.61847L6.3693 8.61595L6.11792 9.04816ZM10.5915 9.04816L10.3401 8.61592L10.3358 8.61849L10.5915 9.04816ZM16.1141 6.41451C16.3528 6.27567 16.4338 5.96962 16.2949 5.73091C16.1561 5.49221 15.8501 5.41125 15.6114 5.55008L16.1141 6.41451ZM10.382 5.75499C10.6499 5.82165 10.9212 5.65846 10.9879 5.39048C11.0545 5.1225 10.8913 4.85123 10.6234 4.78457L10.382 5.75499ZM8.35552 4.73566L8.47622 4.25044C8.39696 4.23073 8.31407 4.23073 8.23481 4.25044L8.35552 4.73566ZM6.08767 4.78457C5.81969 4.85123 5.6565 5.1225 5.72316 5.39048C5.78982 5.65846 6.0611 5.82165 6.32907 5.75499L6.08767 4.78457ZM0.595297 6.41451L5.86654 9.48037L6.3693 8.61595L1.09806 5.55008L0.595297 6.41451ZM5.86221 9.47782C7.39719 10.3914 9.31223 10.3914 10.8472 9.47782L10.3358 8.61849C9.11596 9.34447 7.59346 9.34447 6.37363 8.61849L5.86221 9.47782ZM10.8429 9.48037L16.1141 6.41451L15.6114 5.55008L10.3401 8.61595L10.8429 9.48037ZM10.6234 4.78457L8.47622 4.25044L8.23481 5.22087L10.382 5.75499L10.6234 4.78457ZM8.23481 4.25044L6.08767 4.78457L6.32907 5.75499L8.47622 5.22087L8.23481 4.25044Z'
        fill={color}
      />
    </svg>
  );
};

export default OpenedEmailIcon;
