// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    selectedRowOriginalId: '',
  },
  reducers: {
    setSelectedRowOriginalId(state, action) {
      if (action.payload) {
        return {
          ...state,
          selectedRowOriginalId: action.payload,
        };
      } else {
        return {
          ...state,
          selectedRowOriginalId: '',
        };
      }
    },
  },
});

export const { setSelectedRowOriginalId } = commonSlice.actions;

export default commonSlice.reducer;
