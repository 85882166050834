import { getWorker, resetWorker } from './worker-manager';
import type { Log } from './types';

export async function saveLog(log: Log): Promise<number> {
  try {
    const db = await getWorker();
    const result = await db.query<{ id: number }>(
      `INSERT INTO logs (
      request_id, type, method, url, full_url, query_params, request_body, response_body,
      websocket_payload, status, duration, direction, timestamp, response_size
    ) VALUES ($1, $2, $3, $4, $5, $6, $7, $8, $9, $10, $11, $12, $13, $14)
    RETURNING id`,
      [
        log.request_id,
        log.type,
        log.method,
        log.url,
        log.full_url,
        log.query_params,
        log.request_body,
        log.response_body,
        log.websocket_payload,
        log.status,
        log.duration,
        log.direction,
        log.timestamp,
        log.response_size,
      ],
    );
    return result.rows?.[0]?.id || 0;
  } catch (error) {
    console.error('[DevTools] Error saving log:', error);
    resetWorker();
    return 0;
  }
}

export async function fetchLogs(): Promise<{ logs: Log[]; total: number }> {
  try {
    const db = await getWorker();

    const result = await db.query<Log>('SELECT * FROM logs WHERE id > $1 ORDER BY id LIMIT $2', [0, 1000]);

    if (result.rows.length === 0) {
      return { logs: [], total: 0 };
    }

    return {
      logs: result.rows || [],
      total: result.rows?.length || 0,
    };
  } catch (error) {
    console.error('[DevTools] Error fetching logs:', error);
    resetWorker();
    return { logs: [], total: 0 };
  }
}

export async function clearLogs(): Promise<void> {
  try {
    const db = await getWorker();
    await db.query('TRUNCATE TABLE logs');
    console.info('[DevTools] Logs cleared successfully');
  } catch (error) {
    console.error('[DevTools] Error clearing logs:', error);
    resetWorker();
  }
}
