let socket: WebSocket | null = null;

export const messageHandlers: Record<string, (payload: any) => void> = {};

class WebSocketService {
  connect() {
    socket = new WebSocket('wss://pythonws-sit-poc.financeops.ai/?hostName=financeops');

    socket.onopen = () => {
      console.log('WebSocket connected');
    };

    socket.onmessage = event => {
      try {
        const data = JSON.parse(event.data) || {};
        if (data.recommendations) {
          messageHandlers['recommendations'](data);
        } else if (data.summary) {
          messageHandlers['summary'](data);
        } else if (data.transcript) {
          messageHandlers['transcript'](data);
        } else if (data.aiGeneratedActions) {
          messageHandlers['aiGeneratedActions'](data);
        } else if (data.telepromptedText) {
          messageHandlers['telepromptedText'](data);
        }
      } catch (error) {
        console.error('Error parsing WebSocket message: ', error);
      }
    };

    socket.onclose = () => {
      console.log('WebSocket disconnected');
    };

    socket.onerror = error => {
      console.error('WebSocket error: ', error);
    };
  }

  sendMessage(message: string) {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(message);
    } else {
      console.warn('WebSocket is not connected.');
    }
  }

  disconnect() {
    if (socket) {
      socket.close();
      socket = null;
    }
  }
}
export { socket };
export default new WebSocketService();
