import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { stringify } from 'qs';
import { baseQuery } from './baseQuery';
import {
  ActionGetCountsResponseType,
  ActionsExtensionType,
  FiltersForActionsType,
  GetP2PDtoType,
  GetP2PsCountsResponseType,
  GetP2PsResponseType,
  CreateActionDtoType,
  ResolutionCenterActionsExtensionType,
  CommentType,
  UpdateActionDtoType,
} from '@finance-ops/types';
import { ACTION_CENTER_STATUS_TYPES } from '@finance-ops/constants';
import { toast } from 'react-hot-toast';

interface QueryCacheEntry {
  data?: {
    data: ResolutionCenterActionsExtensionType[];
    originalArgs: FiltersForActionsType;
    comments?: CommentType[];
  };
}

function getErrorMessage(error: any): string {
  // Handle RTK Query error structure
  const errorData = error?.error?.data;

  if (errorData) {
    // If there are specific messages, use the first one
    if (Array.isArray(errorData.messages) && errorData.messages.length > 0) {
      const message = errorData.messages[0].toLowerCase();

      // Map specific error messages to user-friendly versions
      if (message.includes('request entity too large')) {
        return 'The file size is too large. Please try with a smaller file.';
      }

      return errorData.messages[0];
    }

    // Fallback to error field with the same user-friendly mapping
    if (errorData.error) {
      const error = errorData.error.toLowerCase();

      if (error.includes('request entity too large')) {
        return 'The file size is too large. Please try with a smaller file.';
      }

      return errorData.error;
    }
  }

  // Fallback to generic message
  return 'An error occurred. Please try again.';
}

export const actionsApi = createApi({
  reducerPath: 'actionsApi',
  baseQuery: baseQuery,
  tagTypes: ['Actions', 'ActionCounts'],
  endpoints: builder => ({
    getActions: builder.query<{ data: ResolutionCenterActionsExtensionType[]; total: number }, FiltersForActionsType>({
      query: params => ({
        url: '/resolution-center/actions',
        method: 'GET',
        params: {
          ...params,
          status: params.status?.join(','),
          type: params.type,
          clientId: params.clientId || undefined,
          assignedUserId: params.assignedUserId || undefined,
          priority: params.priority || undefined,
        },
      }),
      providesTags: (result, error, params) => [
        { type: 'Actions' as const, id: 'LIST' },
        ...(result?.data?.map(action => ({ type: 'Actions' as const, id: action.id })) ?? []),
        { type: 'Actions' as const, id: params?.type ?? 'ALL' },
      ],
    }),

    getActionCounts: builder.query<ActionGetCountsResponseType[], FiltersForActionsType>({
      query: params => ({
        url: '/resolution-center/actions/counts',
        method: 'GET',
        params: {
          ...params,
          status: params.status?.join(','),
          clientId: params.clientId || undefined,
          assignedUserId: params.assignedUserId || undefined,
        },
      }),
      providesTags: ['ActionCounts'],
    }),

    getActionById: builder.query<ResolutionCenterActionsExtensionType, string>({
      query: id => `/resolution-center/actions/${id}`,
      providesTags: (result, error, id) => [{ type: 'Actions', id }],
    }),

    createAction: builder.mutation<ResolutionCenterActionsExtensionType, CreateActionDtoType>({
      query: body => ({
        url: '/resolution-center/actions',
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          toast.error(getErrorMessage(error));
        }
      },
      invalidatesTags: ['Actions', 'ActionCounts'],
    }),

    updateActionStatus: builder.mutation<
      ResolutionCenterActionsExtensionType,
      { id: string; status: ACTION_CENTER_STATUS_TYPES }
    >({
      query: ({ id, status }) => ({
        url: `/resolution-center/actions/${id}/status`,
        method: 'PATCH',
        body: { status },
      }),
      async onQueryStarted({ id, status }, { dispatch, queryFulfilled, getState }) {
        // Optimistically update getActionById query
        const patchResult = dispatch(
          actionsApi.util.updateQueryData('getActionById', id, draft => {
            if (draft?.userEntity) {
              draft.userEntity.status = status;
            }
          }),
        );

        // Get all cached queries
        const state = getState() as any;
        const queries = state.actionsApi.queries as Record<string, QueryCacheEntry>;

        // Update all matching queries
        const listPatches = Object.entries(queries)
          .filter(([key]) => key.startsWith('getActions('))
          .map(([_, query]) => {
            if (!query.data?.data) return null;

            const args = query.data.originalArgs || {};
            return dispatch(
              actionsApi.util.updateQueryData('getActions', args, draft => {
                const actionIndex = draft.data.findIndex(a => a.id === id);
                if (actionIndex > -1 && draft.data[actionIndex]?.userEntity) {
                  draft.data[actionIndex].userEntity.status = status;
                }
              }),
            );
          })
          .filter(Boolean);

        try {
          await queryFulfilled;
        } catch (error) {
          toast.error(getErrorMessage(error));
          // Undo all optimistic updates on error
          patchResult.undo();
          listPatches.forEach(patch => patch?.undo());
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Actions', id: arg.id }, 'ActionCounts'],
    }),

    updateAction: builder.mutation<ActionsExtensionType, UpdateActionDtoType>({
      query: body => ({
        url: `/resolution-center/actions/${body.id}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        // Optimistically update getActionById query
        const patchResult = dispatch(
          actionsApi.util.updateQueryData('getActionById', patch.id!, draft => {
            if (draft?.userEntity && patch.userEntity) {
              Object.assign(draft.userEntity, patch.userEntity);
            }
          }),
        );

        // Get all cached queries
        const state = getState() as any;
        const queries = state.actionsApi.queries as Record<string, QueryCacheEntry>;

        // Update all matching queries
        const listPatches = Object.entries(queries)
          .filter(([key]) => key.startsWith('getActions('))
          .map(([_, query]) => {
            if (!query.data?.data) return null;

            const args = query.data.originalArgs || {};
            return dispatch(
              actionsApi.util.updateQueryData('getActions', args, draft => {
                const actionIndex = draft.data.findIndex(a => a.id === patch.id);
                if (actionIndex > -1) {
                  if (draft.data[actionIndex].userEntity && patch.userEntity) {
                    Object.assign(draft.data[actionIndex].userEntity, patch.userEntity);
                  }
                }
              }),
            );
          })
          .filter(Boolean);

        try {
          await queryFulfilled;
        } catch (error) {
          toast.error(getErrorMessage(error));
          // Undo all optimistic updates on error
          patchResult.undo();
          listPatches.forEach(patch => patch?.undo());
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Actions', id: arg.id },
        { type: 'Actions', id: arg.type },
        'ActionCounts',
      ],
    }),

    updateResolutionCenterAction: builder.mutation<
      ResolutionCenterActionsExtensionType,
      Partial<ResolutionCenterActionsExtensionType> & { attachments?: any[] }
    >({
      query: body => ({
        url: `/resolution-center/actions/${body.id}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted(patch, { dispatch, queryFulfilled, getState }) {
        // Optimistically update getActionById query
        const patchResult = dispatch(
          actionsApi.util.updateQueryData('getActionById', patch.id!, draft => {
            if (draft?.userEntity && patch.userEntity) {
              Object.assign(draft.userEntity, patch.userEntity);
            }
          }),
        );

        // Get all cached queries
        const state = getState() as any;
        const queries = state.actionsApi.queries as Record<string, QueryCacheEntry>;

        // Update all matching queries
        const listPatches = Object.entries(queries)
          .filter(([key]) => key.startsWith('getActions('))
          .map(([_, query]) => {
            if (!query.data?.data) return null;

            const args = query.data.originalArgs || {};
            return dispatch(
              actionsApi.util.updateQueryData('getActions', args, draft => {
                const actionIndex = draft.data.findIndex(a => a.id === patch.id);
                if (actionIndex > -1) {
                  if (draft.data[actionIndex].userEntity && patch.userEntity) {
                    Object.assign(draft.data[actionIndex].userEntity, patch.userEntity);
                  }
                }
              }),
            );
          })
          .filter(Boolean);

        try {
          await queryFulfilled;
        } catch (error) {
          toast.error(getErrorMessage(error));
          // Undo all optimistic updates on error
          patchResult.undo();
          listPatches.forEach(patch => patch?.undo());
        }
      },
      invalidatesTags: (result, error, arg) => [
        { type: 'Actions', id: arg.id },
        { type: 'Actions', id: arg.type },
        'ActionCounts',
      ],
    }),

    createComment: builder.mutation<
      CommentType,
      {
        content: string;
        commentedById: string;
        referenceId: string;
        referenceType: string;
        createdBy: string;
        attachments?: any[];
        customerId: string;
        clientId: string;
      }
    >({
      query: body => ({
        url: '/comments',
        method: 'POST',
        body,
      }),
      async onQueryStarted(comment, { dispatch, queryFulfilled, getState }) {
        // Create optimistic comment
        const optimisticComment: CommentType = {
          id: Date.now().toString(),
          createdAt: new Date(),
          updatedAt: new Date(),
          version: 1,
          ...comment,
        };

        // Update getActionById query
        const patchResult = dispatch(
          actionsApi.util.updateQueryData('getActionById', comment.referenceId, draft => {
            if (!draft.comments) draft.comments = [];
            draft.comments.push(optimisticComment);
          }),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          toast.error(getErrorMessage(error));
          // Undo optimistic update on error
          patchResult.undo();
        }
      },
      invalidatesTags: (result, error, arg) => [{ type: 'Actions', id: arg.referenceId }],
    }),

    getP2Ps: builder.query<GetP2PsResponseType, GetP2PDtoType>({
      query: (payload: GetP2PDtoType) => {
        const queryParams = stringify(payload);
        return {
          url: `/v2/actions/p2ps?${queryParams}`,
          method: 'GET',
        };
      },
    }),

    getP2PsCounts: builder.query<GetP2PsCountsResponseType, GetP2PDtoType>({
      query: (payload: GetP2PDtoType) => {
        const queryParams = stringify(payload);
        return {
          url: `/v2/actions/p2ps/counts?${queryParams}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetActionsQuery,
  useGetActionCountsQuery,
  useGetActionByIdQuery,
  useCreateActionMutation,
  useUpdateActionMutation,
  useUpdateResolutionCenterActionMutation,
  useUpdateActionStatusMutation,
  useCreateCommentMutation,
  useGetP2PsQuery,
  useGetP2PsCountsQuery,
} = actionsApi;
