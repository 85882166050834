import { ProgressTracker } from '@finance-ops/types';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from './baseQuery';

export interface GetProgressTrackersQueryDto {
  clientId?: string;
  active?: boolean;
}

export interface UpdateProgressTrackerDto {
  isSuccess: boolean;
}

export const progressTrackerApi = createApi({
  reducerPath: 'progressTrackerApi',
  baseQuery: baseQuery,
  tagTypes: ['ProgressTracker'],
  endpoints: builder => ({
    getProgress: builder.query<ProgressTracker, string>({
      query: jobId => ({
        url: `/progress-tracker/bulk-send/${jobId}`,
        method: 'GET',
      }),
      providesTags: (result, error, jobId) => [{ type: 'ProgressTracker', id: jobId }],
    }),

    getAllActiveTrackers: builder.query<ProgressTracker[], GetProgressTrackersQueryDto>({
      query: params => ({
        url: '/progress-tracker/bulk-send',
        method: 'GET',
        params,
      }),
      providesTags: ['ProgressTracker'],
    }),
  }),
});

export const { useGetProgressQuery, useGetAllActiveTrackersQuery } = progressTrackerApi;
