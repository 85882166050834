import { UserDocumentType, UserType } from '@finance-ops/types';
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import api from '../../httpClient/api';
import { baseQuery } from './baseQuery';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: baseQuery,
  tagTypes: ['User'],
  endpoints: builder => ({
    createUser: builder.mutation<UserType, Partial<UserDocumentType>>({
      query: user => ({
        url: api.users,
        method: 'POST',
        body: user,
      }),
      invalidatesTags: ['User'],
    }),
    getUser: builder.query<UserType, { id: string }>({
      query: ({ id }) => ({
        url: `${api.users}/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
    updateUser: builder.mutation<UserType, { user: Partial<UserDocumentType>; id: string }>({
      query: ({ user, id }) => ({
        url: `${api.users}/${id}`,
        method: 'PATCH',
        body: user,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
    deleteUser: builder.mutation<UserType, { id: string }>({
      query: ({ id }) => ({
        url: `${api.users}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'User', id }],
    }),
    removeDevice: builder.mutation<UserType, { userId: string; deviceId: string }>({
      query: ({ userId, deviceId }) => ({
        url: `${api.users}/${userId}/devices/${deviceId}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ userId, deviceId }, { dispatch, queryFulfilled }) {
        // Get the current user data from the cache
        const getUserResult = dispatch(
          usersApi.util.updateQueryData('getUser', { id: userId }, draft => {
            if (draft?.trustedDevices) {
              const deviceIndex = draft.trustedDevices.findIndex(device => device.deviceId === deviceId);
              if (deviceIndex !== -1) {
                draft.trustedDevices.splice(deviceIndex, 1);
              }
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          // If the mutation fails, revert the optimistic update
          getUserResult.undo();
        }
      },
      invalidatesTags: (result, error, { userId }) => [{ type: 'User', id: userId }],
    }),
  }),
});

export const {
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUserQuery,
  useRemoveDeviceMutation,
} = usersApi;
