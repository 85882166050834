import { configureStore } from '@reduxjs/toolkit';

import actions from './action';
import { actionsApi } from './api/actionsApi';
import { clientsApi } from './api/clientsApi';
import { commentsApi } from './api/commentsApi';
import { customerPageCContextApi } from './api/customerPageCContextApi';
import { customerContextApi } from './api/customercontextApi';
import { notificationsApi } from './api/notificationsApi';
import { progressTrackerApi } from './api/progressTrackerApi';
import { usersApi } from './api/usersApi';
import call from './call';
import client from './client';
import common from './common';
import counterParty from './counterparty';
import customerPageCContext from './customerPageCContext';
import customerContext from './customercontext';
import dashboard from './dashboard';
import databaseViewerReducer from './database-viewer';
import email from './email';
import files from './files';
import historyLog from './historyLog';
import invoicePage from './invoicePage';
import language from './language';
import message from './message';
import newWorkflow from './newWorkflow';
import notification from './notification';
import onboarding from './onboarding';
import p2p from './p2p';
import paymentPlans from './paymentPlans';
import payments from './payments';
import predictions from './predictions';
import reports from './report';
import roles from './roles';
import sqsMessage from './sqsMessage';
import strategyBuilder from './strategyBuilder';
import strategyBuilderNew from './strategyBuilderNew';
import teamManagement from './teamManagement';
import teamOverview from './teamOverview';
import template from './template';
import transactions from './transactions';
import users from './users';
import workflow from './workflow';

// ** Toolkit imports

// ** Reducers

export const store = configureStore({
  reducer: {
    actions,
    call,
    client,
    common,
    counterParty,
    customerContext,
    customerPageCContext,
    dashboard,
    databaseViewerReducer,
    email,
    files,
    historyLog,
    invoicePage,
    language,
    message,
    notification,
    onboarding,
    p2p,
    paymentPlans,
    payments,
    predictions,
    reports,
    roles,
    sqsMessage,
    strategyBuilder,
    teamManagement,
    teamOverview,
    template,
    transactions,
    users,
    workflow,
    strategyBuilderNew,
    newWorkflow,
    [customerContextApi.reducerPath]: customerContextApi.reducer,
    [customerPageCContextApi.reducerPath]: customerPageCContextApi.reducer,
    [clientsApi.reducerPath]: clientsApi.reducer,
    [actionsApi.reducerPath]: actionsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [commentsApi.reducerPath]: commentsApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [progressTrackerApi.reducerPath]: progressTrackerApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      customerContextApi.middleware,
      customerPageCContextApi.middleware,
      clientsApi.middleware,
      actionsApi.middleware,
      usersApi.middleware,
      commentsApi.middleware,
      notificationsApi.middleware,
      progressTrackerApi.middleware,
    ),
});

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
